import React from 'react';
import SinglePoem from '../../../components/singlePoem';
import Paper from '@mui/material/Paper'

export default function Poem() {
  return (
    <SinglePoem title="Venus of Willendorf" >
        <p>
        Her recline,<br></br>
        so unnatural at first,<br></br>
        her beaded head<br></br>
        undefined<br></br>
        in its ocularity,<br></br>
        her short feet, nearly<br></br>
        bereft of digits, unable<br></br>
        to point us<br></br>
        in the direction she knows,<br></br>
        her thin, folded arms<br></br>
        too tired or reluctant<br></br>
        to show us where mother<br></br>
        lives, where the earth’s<br></br>
        limestone waits in sediment<br></br>
        for her children to<br></br>
        return—<br></br>
        Thirty thousand years the cord has<br></br>
        stretched,<br></br>
        the snap not far off,<br></br>
        the snap that will either<br></br>
        sever us forever from<br></br>
        or pull us<br></br>
        back<br></br>
        into her fertile, stony bosom.<br></br>
        </p>
    </SinglePoem>
      );
}
